import { lowerCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import CheckboxGroupInput from 'admin/assets/js/components/inputs/CheckboxGroupInput.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const DeactivateOrgButton = ({ label, record, reloadApiTable, resource }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [notifyWhenTerminatingAOR, setNotifyWhenTerminatingAOR] = useState(false);

  if (!record) {
    return null;
  }
  const outstanding = [];
  const isDirectMode = record.invoicing_mode === INVOICING_MODE.DIRECT;
  if (record.outstandingCounts) {
    const ignoreCounts = [];
    if (isDirectMode) {
      ignoreCounts.push('outstandingContractorInvoices');
    }
    Object.keys(record.outstandingCounts).filter(k => !ignoreCounts.includes(k)).forEach(item => {
      if (record.outstandingCounts[item] > 0) {
        outstanding.push(`${lowerCase(item)} (${record.outstandingCounts[item]})`);
      }
    });
  }
  if (outstanding.length) {
    return (
      <>
        <ConfirmModal
          confirm="Ok"
          content={(
            <>
              <p>You cannot deactivate this organisation, because they have outstanding:</p>
              <ul>
                {outstanding.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </>
          )}
          onClose={() => setModalOpen(false)}
          onConfirm={() => setModalOpen(false)}
          open={modalOpen}
          title="Cannot deactivate organisation"
        />
        <MenuItem className="organization_view_tab__card__header--dropdown-menu-item-danger" onClick={() => setModalOpen(true)}>
          {label}
        </MenuItem>
      </>
    );
  }
  const isDeactivated = ORGANIZATION_STATUS.DEACTIVATED === record.status;

  return (
    <BulkApiActionButton
      action="deactivate-org"
      content={(
        <>
          <p>
            {'You are about to deactivate '}
            <bold>{record.name}</bold>
          </p>
          <p>
            Once you deactivate this organisation, none of their users will have access to it. Are
            you sure you want to deactivate this organisation?
          </p>
          <p>
            This change is irreversible. If you want to temporarily prevent access to the
            organization, please suspend it instead.
          </p>
          {isDirectMode && (
            <p>
              Because this organization&apos;s invoicing mode is direct, we will mark all of their
              invoices as paid.
            </p>
          )}
          <p>
            If the organization has any AOR contractors, their AOR contracts will be terminated.
          </p>
          <CheckboxGroupInput
            containerClassName=""
            name="withNotification"
            onChange={() => {
              setNotifyWhenTerminatingAOR(!notifyWhenTerminatingAOR);
            }}
            options={[{
              label: 'Notify all AOR providers about termination via email',
              value: true,
            }]}
            value={notifyWhenTerminatingAOR ? [true] : []}
          />
        </>
      )}
      disabled={isDeactivated}
      ids={record.id}
      label={label}
      payload={{ withAORNotification: !!notifyWhenTerminatingAOR }}
      reloadApiTable={reloadApiTable}
      resource={resource}
      title={`Deactivate ${record.name}`}
    />
  );
};

DeactivateOrgButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  reloadApiTable: PropTypes.bool,
  resource: PropTypes.string,
};

DeactivateOrgButton.defaultProps = {
  label: 'Deactivate organisation',
  reloadApiTable: false,
  record: null,
  resource: null,
};

export default DeactivateOrgButton;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import ConfirmModal from 'admin/assets/js/components/buttons/ConfirmModal.jsx';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';

const ButtonWithConfirm = ({
  className, confirmLabel, disabled, label, modalContent, modalTitle, onConfirm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notifications = getNotifications();
  return (
    <>
      <ConfirmModal
        confirm={confirmLabel}
        content={modalContent}
        disableCtas={loading}
        onClose={() => setModalOpen(false)}
        onConfirm={async () => {
          if (disabled) {
            return;
          }
          setLoading(true);
          try {
            await onConfirm();
            setModalOpen(false);
          } catch (e) {
            notifications.error(e.response?.data?._error || e.message);
          } finally {
            setLoading(false);
          }
        }}
        open={!disabled && modalOpen}
        title={modalTitle}
      />
      <Button
        className={className}
        disabled={disabled || loading}
        onClick={() => setModalOpen(true)}
        size="small"
        variant="outlined"
      >
        {label?.toUpperCase()}
      </Button>
    </>
  );
};

ButtonWithConfirm.propTypes = {
  className: PropTypes.string,
  confirmLabel: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  modalTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ButtonWithConfirm.defaultProps = {
  className: '',
  confirmLabel: 'Ok',
  disabled: false,
};

export default ButtonWithConfirm;

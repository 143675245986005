
import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Menu } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import ChangeOrgOwnerButton from 'admin/assets/js/components/buttons/ChangeOrgOwnerButton.jsx';
import DeactivateOrgButton from 'admin/assets/js/components/buttons/DeactivateOrgButton.jsx';
import ActivateOrgButton from 'admin/assets/js/components/buttons/ActivateOrgButton.jsx';
import SendInvoiceDigestButton from 'admin/assets/js/components/buttons/SendInvoiceDigestButton.jsx';
import IncludeInReportsButton from 'admin/assets/js/components/buttons/IncludeInReportsButton.jsx';
import ExcludeFromReportsButton from 'admin/assets/js/components/buttons/ExcludeFromReportsButton.jsx';
import HijackButton from 'admin/assets/js/components/buttons/HijackButton.jsx';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';

const OrgActionsMenu = ({ record, resource }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClick}
        variant="contained"
        className="organization_view_tab__card__header--dropdown-btn"
      >
        <Icon
          style={{ color: 'white', margin: '0 5px' }}
          fontSize="small"
        >
          {anchorEl ? 'arrow_drop_up' : 'arrow_drop_down'}
        </Icon>
      </Button>
      <Menu
        className="organization_view_tab__card__header--dropdown-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <HijackButton
          record={record}
          label="Hijack organization's owner"
          source="user_id"
          color="primary"
          variant={null}
        />
        <ChangeOrgOwnerButton resource={resource} record={record} />
        <SendInvoiceDigestButton label="" resource={resource} record={record} />
        {record.exclude_from_reporting
          ? (
            <IncludeInReportsButton resource={resource} record={record} />
          ) : (
            <ExcludeFromReportsButton resource={resource} record={record} />
          )
        }
        {record.status === ORGANIZATION_STATUS.ACTIVE && (
          <>
            <BulkApiActionButton
              action="suspend-org"
              content={`
                Once you suspend this organization, their users will be unable to
                access it. Are you sure you want to suspend this organisation?
              `}
              disabled={(
                [ORGANIZATION_STATUS.DEACTIVATED, ORGANIZATION_STATUS.SUSPENDED]
                  .includes(record.status)
              )}
              ids={record.id}
              label="Suspend organization"
              resource={resource}
              title="Suspend Organization"
            />
            <DeactivateOrgButton resource={resource} record={record} />
          </>
        )}
        {record.status === ORGANIZATION_STATUS.SUSPENDED && (
          <ActivateOrgButton resource={resource} record={record} />
        )}
      </Menu>
    </>
  );
};

OrgActionsMenu.propTypes = {
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
};

export default OrgActionsMenu;

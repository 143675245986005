import { ADMIN_URL, ADMIN_API_URL, ADMIN_BULK_API_URL } from 'admin/constants';
import queryString from 'query-string';

export const isAdminUrl = (url) => {
  return url && !!url.match(new RegExp(ADMIN_URL));
};

export const adminResourceUrl = resource => (
  `${ADMIN_URL}/${resource}`
);

export const adminListUrl = resource => adminResourceUrl(resource);

export const adminEditUrl = (resource, id) => (
  `${adminResourceUrl(resource)}/${id}`
);

export const adminShowUrl = (resource, id) => (
  `${adminResourceUrl(resource)}/${id}/show`
);

export const adminCreateUrl = resource => (
  `${adminResourceUrl(resource)}/create`
);

export const adminRootUrl = () => (
  ADMIN_URL
);

export const adminUserProfileUrl = (userId, tab = null, subTab = null) => (
  `${ADMIN_URL}/full_users/${userId}/show${tab ? `?tab=${tab}` : ''}${subTab ? `&subTab=${subTab}` : ''}`
);

export const adminQueuesBoardUrl = () => (
  `${adminRootUrl()}/queues-board`
);

export const adminLoginUrl = () => (
  `${ADMIN_URL}/open`
);

export const adminInvoiceDownloadUrl = id => (
  `${ADMIN_URL}/invoices/${id}/download`
);

// API
export const adminApiUrl = (resource, id) => (
  id ? `${ADMIN_API_URL}/${resource}/${id}` : `${ADMIN_API_URL}/${resource}`
);

export const adminApiStatusUrl = () => (
  `${ADMIN_API_URL}/status`
);

export const adminCommandsBaseApiUrl = () => (
  `${ADMIN_API_URL}/commands`
);

export const adminCommandApiUrl = (action, query) => (
  `${ADMIN_API_URL}/commands/${action}?${queryString.stringify(query)}`
);

export const adminBulkApiUrl = resource => (
  `${ADMIN_BULK_API_URL}/${resource}`
);

export const adminResourceApiUrl = resource => (
  `${ADMIN_API_URL}/${resource}`
);

export const adminBulkActionApiUrl = (resource, action) => (
  `${ADMIN_BULK_API_URL}/${resource}/${action}`
);

export const adminApiRootUrl = () => (
  ADMIN_API_URL
);

export const adminApiLoginUrl = () => (
  `${ADMIN_API_URL}/open`
);

export const adminApiHijackUrl = () => (
  `${ADMIN_API_URL}/hijack`
);

export const adminApiExchangeRateUrl = currency => (
  `${ADMIN_API_URL}/exchange-rate/${currency}`
);

export const adminApiUpcomingBillingProcessesUrl = () => (
  `${ADMIN_API_URL}/upcoming-billing-processes`
);

export const adminApiLastInvoicingProcessStatusUrl = (orgId, billingDeadline) => {
  const query = queryString.stringify({ billingDeadline, orgId }, { encode: false });
  return `${ADMIN_API_URL}/last-invoicing-process-status?${query}`;
};

export const adminAorContractUploadApiUrl = () => (
  `${ADMIN_API_URL}/aor-contract-upload`
);

export const adminAorContractDownloadApiUrl = aorContractId => (
  `${ADMIN_API_URL}/aor-contract/${aorContractId}/download`
);

export const adminAorContractRejectApiUrl = () => (
  `${ADMIN_API_URL}/aor-contract-reject`
);

export const adminApiAORTemplatesUrl = () => `${ADMIN_API_URL}/aor-onboarding-templates`;

export const adminApiBannerCreateUrl = () => `${ADMIN_API_URL}/site_wide_banners`;
export const adminApiBannerUrl = id => `${adminApiBannerCreateUrl()}/${id}`;
export const adminApiBannerEditUrl = adminApiBannerUrl;
export const adminApiBannerDeleteUrl = adminApiBannerUrl;
export const adminApiBannerToggleActiveUrl = id => (
  `${ADMIN_API_URL}/site-wide-banners/${id}/toggle-active`
);

export const adminApiOrganizationsListUrl = () => `${ADMIN_API_URL}/organizations-list`;

export const adminApiAMLCheckCreateUrl = () => `${ADMIN_API_URL}/user_aml_checks`;
export const adminApiAMLManualCheckCreateUrl = userId => (
  `${ADMIN_API_URL}/aml-check-manual/${userId}`
);
export const adminApiAMLExpiredUpdateUrl = userAMLCheckId => (
  `${ADMIN_API_URL}/update-expired-aml-check/${userAMLCheckId}`
);

export const adminApiConvertToAORUrl = userCardId => (
  `${ADMIN_API_URL}/convert-to-aor/${userCardId}`
);
export const adminApiTerminateAORUrl = userCardId => (
  `${ADMIN_API_URL}/terminate-aor/${userCardId}`
);

export const adminApiBlockedEmailDomainCreateUrl = () => `${ADMIN_API_URL}/blocked_email_domains`;
export const adminApiBlockedEmailDomainDeleteUrl = id => (
  `${adminApiBlockedEmailDomainCreateUrl()}/${id}`
);

export const adminApiActiveOrganizationsManagersList = () => `${ADMIN_API_URL}/active-organizations-managers-list`;

export const adminApiGetCurrentClassificationQuestionnaireUrl = () => (
  `${ADMIN_API_URL}/classification-questionnaire-current`
);
export const adminApiCreateNewVersionClassificationQuestionnaireUrl = () => (
  `${ADMIN_API_URL}/classification-questionnaire`
);
export const adminApiCreateClassificationSubmissionEventUrl = id => (
  `${ADMIN_API_URL}/classification-submissions/${id}/event`
);

export const adminApiUserNoteUrl = () => `${ADMIN_API_URL}/user_notes`;

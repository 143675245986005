import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Paper, Grid, Chip } from '@material-ui/core';
import { Link, useRefresh } from 'react-admin';

import AorContractReviewAndSignModal from 'admin/assets/js/components/AorContractReviewAndSignModal.jsx';
import CheckboxGroupInput from 'admin/assets/js/components/inputs/CheckboxGroupInput.jsx';
import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import ButtonWithConfirm from 'admin/assets/js/components/buttons/ButtonWithConfirm.jsx';
import ToggleWithConfirmationButton from 'admin/assets/js/components/buttons/ToggleWithConfirmationButton.jsx';
import ContractUploadButton from 'admin/assets/js/components/buttons/ContractUploadButton.jsx';
import AorContractRejectButton from 'admin/assets/js/components/buttons/AorContractRejectButton.jsx';
import apiClient from 'admin/assets/js/lib/apiClient';
import { AOR_CONTRACT_STATUS, AOR_CONTRACT_STATUS_LABEL } from 'documents/assets/js/constants';
import { AOR_STATUS, AOR_STATUS_COLORS } from 'admin/assets/js/resources/full_users/constants';
import { WINDOW_RELOAD } from 'core/assets/js/config/settings';
import { USER_CARD_STATUS } from 'core/assets/js/constants';
import { useStyles } from 'admin/assets/js/resources/full_users/UserOrgInfo.jsx';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { formatDate, reloadTable } from 'admin/assets/js/lib/utils';
import { adminApiConvertToAORUrl, adminApiTerminateAORUrl } from 'admin/urls';

const UserOrgAorTab = ({ userCard, profile, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [notifyWhenTerminatingAOR, setNotifyWhenTerminatingAOR] = useState(false);

  if (!userCard || !profile) {
    return null;
  }

  const {
    id: userCardId, aorEnabled, aorEnabledAt, aorDisabledAt, invitation: { aor, aorConvertedBy },
  } = userCard;
  const { firstName, lastName } = profile;

  const toggleAorData = {
    aor_enabled: !aorEnabled,
  };
  if (aorEnabled) {
    toggleAorData.aor_disabled_at = moment();
  }
  if (!aorEnabled) {
    toggleAorData.aor_enabled_at = moment();
  }

  const onClose = () => {
    reloadTable();
    refresh();
  };

  const notifications = getNotifications();

  return (
    <Paper style={{ padding: '15px' }}>
      <Grid container spacing={2}>
        { userCard.isEmployee && (
          'They are an employee in this organization, so are not eligible for AOR'
        )}

        { !userCard.isEmployee && (
          <Grid item xs={12}>
            <div className={classes.labelStyle}>AOR status</div>

            <div className="d-flex align-items-center">
              <Chip
                style={{
                  backgroundColor: aorEnabled
                    ? AOR_STATUS_COLORS[AOR_STATUS.ENABLED]
                    : AOR_STATUS_COLORS[AOR_STATUS.DISABLED],
                  color: 'white',
                }}
                size="small"
                label={aorEnabled ? 'Enabled' : 'Disabled'}
              />

              <time
                className="ml-2"
                dateTime={formatDate(aorEnabledAt, 'YYYY-MM-DD HH:mm:ss')}
              >
                {formatDate(aorEnabledAt, 'DD/MM/YY HH:mm')}
              </time>

              <ContractUploadButton
                userCard={userCard}
                onSuccess={reloadTable}
              />

              {!aorEnabled && aorDisabledAt && (
                <time
                  className="ml-2"
                  dateTime={formatDate(aorDisabledAt, 'YYYY-MM-DD HH:mm:ss')}
                >
                  {formatDate(aorDisabledAt, 'DD/MM/YY HH:mm')}
                </time>
              )}
            </div>

            <div className="d-flex">
              <ToggleWithConfirmationButton
                className={`mt-4 ${aorEnabled ? classes.button_disable : ''}`}
                record={userCard}
                resource="user_cards"
                newValues={toggleAorData}
                label={`Manually ${aorEnabled ? 'dis' : 'en'}able AOR`}
                confirmationModalTitle={(
                  `Are you sure you want to ${aorEnabled ? 'dis' : 'en'}able AOR for this user?`
                )}
                confirmationModalContent={(
                  <p>
                    {`You are about to ${aorEnabled ? 'dis' : 'en'}able AOR
                    for ${firstName} ${lastName}.`}
                  </p>
                )}
                onSuccess={() => {
                  // Workaround to fix record not being updated after 2nd toggle
                  WINDOW_RELOAD();
                }}
              />

              <ButtonWithConfirm
                className="mt-4 ml-2"
                disabled={aorEnabled || !!aorConvertedBy || aor}
                label="Request user complete the AOR process"
                modalContent={(
                  <p>
                    Are you sure you want to request that the user complete the AOR process?
                  </p>
                )}
                modalTitle="Request user complete the AOR process"
                onConfirm={async () => {
                  await apiClient({
                    method: 'POST', url: adminApiConvertToAORUrl(userCard.id),
                  });
                  notifications.success('AOR get started steps enabled for this user');
                  WINDOW_RELOAD();
                }}
              />
            </div>

            <ApiTable
              {...props}
              columns={[
                {
                  label: 'Date signed',
                  name: 'signed_at',
                  options: {
                    cellRender: (value, record) => formatDate(record.signedAt),
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: 'Date countersigned',
                  name: 'countersigned_at',
                  options: {
                    cellRender: (value, record) => formatDate(record.counterSignedAt),
                    filter: false,
                    sort: true,
                  },
                },
                {
                  label: 'status',
                  name: 'status',
                  options: {
                    cellRender: status => (
                      <Chip
                        style={{
                          backgroundColor: status === AOR_CONTRACT_STATUS.ACTIVE
                            ? AOR_STATUS_COLORS[AOR_STATUS.ENABLED]
                            : AOR_STATUS_COLORS[AOR_STATUS.DISABLED],
                          color: 'white',
                        }}
                        size="small"
                        label={AOR_CONTRACT_STATUS_LABEL[status]}
                      />
                    ),
                    filter: true,
                    sort: true,
                  },
                },
                {
                  label: 'Contract',
                  name: 'fileUrl',
                  options: {
                    cellRender: fileUrl => (
                      fileUrl ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={fileUrl}
                        >
                          <span className="text-uppercase">View contract</span>
                        </a>
                      ) : '-'
                    ),
                    filter: false,
                    sort: false,
                  },
                },
                {
                  label: 'Uploaded by',
                  name: 'createdBy',
                  options: {
                    cellRender: (creator, record) => (
                      creator
                        ? (
                          <>
                            <Link to={`/full_users/${creator.id}/show`}>
                              {creator.name}
                            </Link>
                            <br />
                            <span className="discreet">{formatDate(record.createdAt, 'DD/MM/YY HH:mm')}</span>
                          </>
                        ) : '-'
                    ),
                    filter: false,
                    sort: false,
                  },
                },
                {
                  label: ' ',
                  name: 'status',
                  options: {
                    cellRender: (status, record) => {
                      const showAorContractRejectButton = [
                        AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN,
                        AOR_CONTRACT_STATUS.PENDING_PROVIDER_SIGNATURE,
                      ].includes(status);

                      let cellWidth;
                      if (status === AOR_CONTRACT_STATUS.ACTIVE) {
                        cellWidth = '150px';
                      } else if (status === AOR_CONTRACT_STATUS.PENDING_PROVIDER_SIGNATURE) {
                        cellWidth = '180px';
                      } else if (status === AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN) {
                        cellWidth = '300px';
                      }
                      return (
                        <div
                          className="text-right"
                          style={{ minWidth: cellWidth }}
                        >
                          {status === AOR_CONTRACT_STATUS.ACTIVE && (
                            <>
                              <ToggleWithConfirmationButton
                                record={record}
                                color="primary"
                                variant="outlined"
                                resource="user_card_aor_contracts"
                                newValues={{ status: AOR_CONTRACT_STATUS.DELETED }}
                                label="Delete contract"
                                confirmationModalTitle="Are you sure you want to delete this AOR Contract?"
                                confirmationModalContent="Contract file will remain available."
                                onSuccess={reloadTable}
                              />
                              {userCard.status === USER_CARD_STATUS.DEACTIVATED && (
                                <ButtonWithConfirm
                                  className="mt-3"
                                  confirmLabel="Terminate"
                                  label="Terminate contract"
                                  modalContent={(
                                    <>
                                      <p>
                                        {`You are about to terminate ${profile.name}'s AOR `}
                                        {`contract in ${userCard.organization.name}.`}
                                      </p>
                                      <CheckboxGroupInput
                                        containerClassName=""
                                        name="withNotification"
                                        onChange={() => {
                                          setNotifyWhenTerminatingAOR(!notifyWhenTerminatingAOR);
                                        }}
                                        options={[{
                                          label: (
                                            'Notify the provider about the termination via email'
                                          ),
                                          value: true,
                                        }]}
                                        value={notifyWhenTerminatingAOR ? [true] : []}
                                      />
                                    </>
                                  )}
                                  modalTitle="Terminate AOR contract"
                                  onConfirm={async () => {
                                    await apiClient({
                                      data: { withNotification: !!notifyWhenTerminatingAOR },
                                      method: 'POST',
                                      url: adminApiTerminateAORUrl(userCard.id),
                                    });
                                    notifications.success(
                                      'AOR contract terminated for this provider',
                                    );
                                    setNotifyWhenTerminatingAOR(false);
                                    WINDOW_RELOAD();
                                  }}
                                />
                              )}
                            </>
                          )}

                          {status === AOR_CONTRACT_STATUS.PENDING_COUNTERSIGN && (
                            <AorContractReviewAndSignModal
                              envelope_signing_embed={record.envelope_signing_embed}
                              onClose={onClose}
                            />
                          )}

                          {showAorContractRejectButton && (
                            <AorContractRejectButton
                              className={classes.aor_contract_reject}
                              aorContract={record}
                              onSuccess={reloadTable}
                            />
                          )}
                        </div>
                      );
                    },
                    filter: false,
                    sort: false,
                  },
                },
              ]}
              className="mt-5"
              defaultSortBy="signed_at"
              defaultSortDirection="ASC"
              downloadEnabled={false}
              draggableColumns={false}
              extraResourceQueryParams={{ user_card_id: userCardId }}
              resource="user_card_aor_contracts"
              searchEnabled={false}
              useQueryParams={false}
              viewColumnsEnabled={false}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

UserOrgAorTab.propTypes = {
  userCard: PropTypes.object,
  profile: PropTypes.object,
};

UserOrgAorTab.defaultProps = {
  userCard: null,
  profile: null,
};

export default UserOrgAorTab;

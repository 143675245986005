import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import React from 'react';
import { Admin } from 'react-admin';
import ReactDom from 'react-dom';
import { Route } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';

import englishMessages from 'admin/assets/js/i18n/en';
import authProvider from 'admin/assets/js/lib/authProvider';
import history from 'admin/assets/js/lib/history';
import httpClient from 'admin/assets/js/lib/httpClient';
import { NotificationsProvider, ShowNotifications } from 'admin/assets/js/lib/notifications.jsx';
import resources from 'admin/assets/js/resources';
import 'admin/assets/sass/admin.scss';
import { adminApiRootUrl } from 'admin/urls';
import { DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';

import Dashboard from 'admin/assets/js/Dashboard.jsx';
import IntegrationsAvailability from 'admin/assets/js/components/IntegrationsAvailability.jsx';
import Layout from 'admin/assets/js/layout/Layout.jsx';
import BackgroundJobsView from 'admin/assets/js/BackgroundJobsView.jsx';
import BannerCreateView from 'admin/assets/js/BannerCreateView.jsx';
import BannerEditView from 'admin/assets/js/BannerEditView.jsx';
import BannersView from 'admin/assets/js/BannersView.jsx';
import BlockedEmailDomainsView from 'admin/assets/js/BlockedEmailDomainsView.jsx';
import ClassificationView from 'admin/assets/js/ClassificationView.jsx';
import ClassificationCreateView from 'admin/assets/js/ClassificationCreateView.jsx';
import ClassificationNewVersionView from 'admin/assets/js/ClassificationNewVersionView.jsx';
import ClassificationSubmissionView from 'admin/assets/js/ClassificationSubmissionView.jsx';
import LoginForm from 'admin/assets/js/components/LoginForm.jsx';
import Settings from 'admin/assets/js/components/Settings.jsx';
import TeamView from 'admin/assets/js/TeamView.jsx';
import OrganizationsToBeDeactivatedView from 'admin/assets/js/OrganizationsToBeDeactivatedView.jsx';
import UserAMLChecksView from 'admin/assets/js/UserAMLChecksView.jsx';
import UserCardAORContractsView from 'admin/assets/js/UserCardAORContractsView.jsx';
import CustomBankAccountsView from 'admin/assets/js/CustomBankAccountsView.jsx';
import CustomSystemBankAccountsView from 'admin/assets/js/CustomSystemBankAccountsView.jsx';
import CustomSettingsGeneralView from 'admin/assets/js/CustomSettingsGeneralView.jsx';
import CustomSettingsMappingView from 'admin/assets/js/CustomSettingsMappingView.jsx';
import ReportsDownloadsView from 'admin/assets/js/resources/reports/ReportsDownloadsView.jsx';
import ReportsSignNowView from 'admin/assets/js/resources/reports/ReportsSignNowView.jsx';
import { REPORTS_TABS, SETTINGS_TABS } from 'admin/assets/js/constants';
import CustomTransactionsDataView from 'admin/assets/js/CustomTransactionsDataView.jsx';
import CustomTransactionsView from 'admin/assets/js/CustomTransactionsView.jsx';
import CustomCashoutRequestsView
  from 'admin/assets/js/resources/cashout_requests/CustomCashoutRequestsView.jsx';
import CustomDeferredInvoicesView
  from 'admin/assets/js/resources/cashout_requests/CustomDeferredInvoicesView.jsx';


const tdBlue = {
  500: '#4578ab',
};

const tdPink = {
  main: '#f50057',
};

const tdRed = {
  main: '#f44336',
};


const themeDefinition = {
  palette: {
    primary: tdBlue,
    secondary: tdPink,
    error: tdRed,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontSize: 20,
    fontFamily: [
      'Arial',
      'sans-serif',
    ].join(','),
  },
};

const theme = createTheme(themeDefinition);

const dataProvider = jsonServerProvider(adminApiRootUrl(), httpClient);

const i18nProvider = polyglotI18nProvider(() => (
  // Always fallback on english
  englishMessages
));

const customRoutes = [
  <Route exact path="/integrations-availability" component={IntegrationsAvailability} />,
  <Route exact path="/open" component={LoginForm} noLayout />,
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/background-jobs" component={BackgroundJobsView} />,
  <Route exact path="/banners" component={BannersView} />,
  <Route exact path="/blocked-email-domains" component={BlockedEmailDomainsView} />,
  <Route exact path="/banners/create" component={BannerCreateView} />,
  <Route exact path="/banners/:id/edit" component={BannerEditView} />,
  <Route exact path="/team" component={TeamView} />,
  <Route exact path="/organizations-to-be-deactivated" component={OrganizationsToBeDeactivatedView} />,
  <Route exact path="/aml-checks" component={UserAMLChecksView} />,
  <Route exact path="/aor-contracts" component={UserCardAORContractsView} />,
  <Route exact path="/bank_accounts/all" component={CustomBankAccountsView} />,
  <Route exact path="/bank_accounts/system" component={CustomSystemBankAccountsView} />,
  <Route exact path="/transactions/:id/data" component={CustomTransactionsDataView} />,
  <Route exact path="/transactions/:id/details" component={CustomTransactionsView} />,
  <Route exact path="/cashout_requests/requests" component={CustomCashoutRequestsView} />,
  <Route exact path="/cashout_requests/deferred_invoices" component={CustomDeferredInvoicesView} />,
  <Route exact path={`/settings/${SETTINGS_TABS.GENERAL}`} component={CustomSettingsGeneralView} />,
  <Route exact path={`/settings/${SETTINGS_TABS.MAPPING}`} component={CustomSettingsMappingView} />,
  <Route exact path="/classification-questionnaire" component={ClassificationView} />,
  <Route exact path="/classification-questionnaire-create" component={ClassificationCreateView} />,
  <Route
    component={ClassificationNewVersionView}
    exact
    path="/classification-questionnaire-new-version"
  />,
  <Route path="/classification-questionnaire/:id" component={ClassificationSubmissionView} />,
  <Route exact path={`/reports/${REPORTS_TABS.DOWNLOADS}`} component={ReportsDownloadsView} />,
  <Route exact path={`/reports/${REPORTS_TABS.SIGNNOW}`} component={ReportsSignNowView} />,
];

const App = () => {
  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <NotificationsProvider>
      <ShowNotifications />
      <Admin
        authProvider={authProvider}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        history={history}
        i18nProvider={i18nProvider}
        layout={Layout}
        theme={theme}
        title="Admin Panel"
      >
        {resources}
      </Admin>
    </NotificationsProvider>
  );
};

const launchApp = () => {
  const $mount = DOCUMENT_GET_ELEMENT_BY_ID('mount-container');
  if ($mount) {
    const renderMethod = module.hot ? ReactDom.render : ReactDom.hydrate;
    renderMethod(
      <App />,
      $mount,
    );
  }
};

launchApp();

export default launchApp;
